import STATES from 'constants/States';
import compact from './compact';

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage: (message: string) => void;
    };
  }
}

// order matters
const FAN_APP_ROUTES: {
  regex: RegExp;
  route: (params: any) => string;
}[] = [
  {
    regex: /teams\/(?<teamId>\d+).+players\/(?<playerId>\d+)/,
    route: ({ teamId, playerId }: { teamId: string; playerId: string }) => `/players/${teamId}/${playerId}`
  },
  {
    regex: /teams\/(?<teamId>\d+).+(?<tab>games|players|rankings|standings|stats|stories)/,
    route: ({ teamId, tab }: { teamId: string; tab: string }) => {
      const screen = (() => {
        switch (tab) {
          case 'games':
            return 'TEAMS_SCHEDULE_SCREEN';
          case 'players':
            return 'TEAMS_ROSTER_SCREEN';
          case 'rankings':
            return 'TEAMS_RANKINGS_SCREEN';
          case 'standings':
            return 'TEAMS_STANDINGS_SCREEN';
          case 'stats':
            return 'TEAMS_STATS_SCREEN';
          case 'stories':
            return 'TEAMS_NEWS_SCREEN';
        }
      })();

      return `/teams/${teamId}?screen=${screen}`;
    }
  },
  {
    regex: /teams\/(?<teamId>\d+)/,
    route: ({ teamId }: { teamId: string }) => `/teams/${teamId}?screen=TEAM_HOME_SCREEN` // issue where params aren't removed by React Native Navigation
  },
  {
    regex: /games\/(?<gameId>\d+).+(?<tab>boxscore|preview|recap|scorebookcast)/,
    route: ({ gameId, tab }: { gameId: string; tab: string }) => `/games/${gameId}?tab=${tab}`
  },
  {
    regex: /games\/(?<gameId>\d+)/,
    route: ({ gameId }: { gameId: string }) => `/games/${gameId}?tab=` // issue where params aren't removed by React Native Navigation
  },
  {
    regex: /(tournaments|brackets)\/(?<bracketIdOrSlug>[^/]+)\/(?<tab>more|scores|teams|pickem)/,
    route: ({ bracketIdOrSlug, tab }: { bracketIdOrSlug: string; tab: string }) => `/playoffs/${bracketIdOrSlug}?tab=${tab}`
  },
  {
    regex: /(tournaments|brackets)\/(?<bracketIdOrSlug>.+)/,
    route: ({ bracketIdOrSlug }: { bracketIdOrSlug: string }) => `/playoffs/${bracketIdOrSlug}?tab=` // issue where params aren't removed by React Native Navigation
  },
  {
    regex: /(?<stateSlug>[a-z-]+)\/(?<genderSportSlug>[a-z-]+)\/(?<pageType>games|scores|rankings|brackets|tournaments|teams)/,
    route: ({ stateSlug, genderSportSlug, pageType }: { stateSlug: string; genderSportSlug: string; pageType: string }) => {
      const stateAbbrev = STATES.find(state => state.slug === stateSlug)?.abbrev;

      const route = pageType.replace('scores', 'games').replace('tournaments', 'brackets');

      return `/${route}?stateAbbrev=${stateAbbrev}&genderSportSlug=${genderSportSlug}`;
    }
  },
  {
    regex: /(?<stateOrGenderSportSlug>[a-z-]+)\/(?<pageType>games|scores|rankings|brackets|tournaments|teams)/,
    route: ({ stateOrGenderSportSlug, pageType }: { stateOrGenderSportSlug: string; pageType: string }) => {
      const stateAbbrev = STATES.find(state => state.slug === stateOrGenderSportSlug)?.abbrev;
      const genderSportSlug = stateAbbrev ? undefined : stateOrGenderSportSlug;

      const route = pageType.replace('scores', 'games').replace('tournaments', 'brackets');

      return [`/${route}?`, stateAbbrev && `stateAbbrev=${stateAbbrev}`, genderSportSlug && `genderSportSlug=${genderSportSlug}`]
        .filter(compact)
        .join('');
    }
  },
  {
    regex: /20\d{2}\/\d{2}\/\d{2}/, // matches date .. maybe a better way to determine articles?
    route: ({ url }: { url: string }) => `/article?articleUrl=${encodeURIComponent(url)}`
  }
];

export function findFanAppRoute(url: string) {
  const route = FAN_APP_ROUTES.find(route => route.regex.test(url));
  if (!route) return;

  const result = route.regex.exec(url);

  return route.route({ url, ...(result?.groups || {}) });
}

export function interceptFanAppUrl(url: string) {
  if (!window.ReactNativeWebView) return false;

  if (url.startsWith('sblive') || url.startsWith('http')) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ href: url, route: url.replace('sblive://', '') })); // TODO eventually remove route here once app fully updated
    return true;
  }

  return false;
}
