import { ReactNode, MouseEvent, useEffect } from 'react';
import styles from './styles/Modal.module.scss';
import ReactDOM from 'react-dom';
import Chevron from 'icons/Chevron';
import Clickable from './Clickable';
import useScreenSize from 'hooks/useScreenSize';
import cn from 'classnames';

let modalStack = 0;

interface ModalProps {
  onClose: () => void;
  children?: ReactNode;
  title?: ReactNode;
  closeAction?: ModalCloseAction;
  header?: ReactNode;
  footer?: ReactNode;
  hidden?: boolean;
  fullScreenSmall?: boolean;
}

interface ModalOverlayProps {
  onClose: () => void;
  children?: ReactNode;
  hidden?: boolean;
  fullScreenSmall?: boolean;
  overlayClassName?: string;
}

export enum ModalCloseAction {
  CLOSE = 'CLOSE',
  BACK = 'BACK'
}

export function ModalCloseButton({ onClose, className }: { onClose: ModalProps['onClose']; className?: string }) {
  return (
    <Clickable onClick={onClose} className={className}>
      <svg width="14.006" height="14.044" viewBox="0 0 14.006 14.044">
        <path
          d="M13.661,107.383a1.05,1.05,0,0,1-1.486,1.486L7,103.658l-5.209,5.209a1.05,1.05,0,0,1-1.486-1.486l5.211-5.207L.308,96.926A1.05,1.05,0,1,1,1.793,95.44L7,100.69l5.209-5.209A1.05,1.05,0,0,1,13.7,96.967l-5.211,5.207Z"
          transform="translate(0 -95.133)"
        />
      </svg>
    </Clickable>
  );
}

function handleModalClick(e: MouseEvent<HTMLDivElement>) {
  e.stopPropagation();
}

export function ModalOverlay({ onClose, children, hidden, fullScreenSmall = true, overlayClassName }: ModalOverlayProps) {
  const { isSmallScreen } = useScreenSize();

  // This would likely be better in ApplicationContainer
  function addToStack() {
    modalStack++;
    if (modalStack === 1) {
      const wrapper = document.getElementsByTagName('main')[0];
      if (!wrapper) return;
      wrapper.style.top = `-${window.scrollY}px`;
      wrapper.style.width = '100%';
      wrapper.style.position = 'fixed';
    }
  }

  function removeFromStack() {
    modalStack--;
    if (modalStack === 0) {
      const wrapper = document.getElementsByTagName('main')[0];
      if (!wrapper) return;
      const scrollY = wrapper.style.top;
      wrapper.style.position = '';
      wrapper.style.top = '';
      wrapper.style.width = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }

  useEffect(() => {
    if (isSmallScreen) addToStack();
    return () => {
      if (isSmallScreen) removeFromStack();
    };
  }, [isSmallScreen]);

  function handleClose() {
    onClose();
  }

  return ReactDOM.createPortal(
    <div
      className={cn(overlayClassName ? overlayClassName : styles.ModalContainer, {
        [styles.Hidden]: hidden,
        [styles.Window]: !fullScreenSmall || !isSmallScreen
      })}
      onClick={handleClose}
    >
      {children}
    </div>,
    document.body
  );
}

export default function Modal({
  onClose,
  children,
  title,
  closeAction = ModalCloseAction.CLOSE,
  footer,
  header,
  hidden,
  fullScreenSmall
}: ModalProps) {
  return (
    <ModalOverlay onClose={onClose} hidden={hidden} fullScreenSmall={fullScreenSmall}>
      <div className={styles.Modal} onClick={handleModalClick}>
        <div className={cn(styles.ModalHeader, { [styles.ModalHeaderDefault]: !header })}>
          {header || (
            <>
              {closeAction === ModalCloseAction.BACK && (
                <Clickable onClick={onClose}>
                  <Chevron direction="left" height={14} width={7.5} />
                </Clickable>
              )}
              <div className={styles.ModalTitle}>{title}</div>
              {closeAction === ModalCloseAction.CLOSE && <ModalCloseButton onClose={onClose} />}
            </>
          )}
        </div>
        <div className={styles.ModalContent}>{children}</div>
        {footer && <div className={styles.ModalFooter}>{footer}</div>}
      </div>
    </ModalOverlay>
  );
}
